// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@font-face {
    src: url('../assets/fonts/good_times/good-times-rg.otf');
    font-family: "good-times";
}

@font-face {
    src: url('../assets/fonts/digital_7/digital-7.ttf');
    font-family: "digital7";
}

:root{
    --grey_background: #ededed;
    --red_element: #d90122;
}

html,body{
    font-size: 15px;
    font-family: 'Nunito', sans-serif;
}

h1, h2, h3, p, a, span, div{
    font-family: 'Nunito', sans-serif;
}

h2{
	font-size:1.2rem;
}

.container{
    max-width: 1480px;
}

.research_title{
	flex:2;
}
.research_input{
	flex: 1;
	min-width: 350px;
	max-width: 100%;
}

table.list-items img{
	width: 24px;
}

.sort_icon{
    width: 14px !important;
    cursor: pointer;
}

.fs-7{
    font-size: 0.9rem;
}

.password_eye{
    width:24px;
    height: 24px;
    top:6px;
    right:20px;
    cursor: pointer;
    background: url('/img/eye.png') center center no-repeat;
    background-size: cover;
}

.eye-close{
    background-image: url('/img/eye-close.png') !important;
}

.password_input .password_eye{
    top: 37px;
}

#terms_list{
    width: 220px;
}

@media all and (max-width: 991px){
    #terms_list{
        width: 150px;
    }
}

@media all and (max-width: 300px){
    #terms_list{
        width: 120px;
    }
}

/* ******** Weighing INDEX ******** */
.grouped_action form{
	gap: 1.4rem;
}
.col-size-125{
    min-width: 125px;
}
.col-size-180{
    min-width: 180px;
}

.col-size-450{
    min-width: 450px;
}

table.checking_list tr{
    background-color: #fff;
}

table.checking_list tr.selected{
    background-color: #F7E6D8;
}

table.checking_list :not(thead) tr:hover{
    background-color: #DCE3F1;
    cursor: pointer;
}

table .weighing_thumbnail{
    width: 70px;
    height: auto;
}

@media all and (max-width: 575px) {

    table#weighing_list th, table#weighing_list td{
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        font-size: 0.9rem;
    }

    table .weighing_thumbnail{
        width: 85px;
        height: auto;
    }
}
/* ******** Weighing SHOW ******** */
.weighing_img_box{
	width: 200px;
	height: 200px;
}

.weighing_img_sub_box{
    width: 75px;
    height: 75px;
}

.weighing_img_box img, .weighing_img_sub_box img{
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position:center;
    display: block;
}

.platform_dispatcher{

    > div{
        padding: 1rem;
        flex-basis: 50%;
    }

    .platform_identifier{
        background-color: var(--grey_background);

        img{
            width: 40px;
            height: auto;
        }

        .platform_position{
            font-size: 2rem;
            color: var(--red_element);
            font-family: digital7, sans-serif;
        }
    }
}

.weighing_user_info img{
    width: 35px;
    height: auto;
}

.table_weighing_details tbody tr:nth-child(even),  .table_weighing_details tfoot tr{
    background-color: #CCCCCC;
}

.table_weighing_details thead th{
    text-transform: capitalize;
}



.total_weighing{
    .total_heading{
        background-color: #D9D9D9;
        font-family: good-times, sans-serif;
        font-size: 1rem;
        line-height: 2rem;
    }

    .total_net_heading{
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 700;
    }

    .total_net_heading, .total_brut_heading, .total_brut_heading{
        width: 100px;
    }

    .total_value{
        background-color: #3A3838;
        color: #fff;
        font-family: 'digital7', sans-serif;
    }

    .total_net_value p{
        font-family: 'digital7', sans-serif;
        font-size: 5rem;
        line-height: 1;
    }

    .total_unity{
        font-family: 'Nunito', sans-serif;
    }

    p.total_label{
        bottom: 3px;
        left: 5px;
        font-family: 'Nunito', sans-serif;
        font-size: 0.8rem;
    }
}



/* ********* PLATFORM SHOW ***** */
#terms_list ul{
    position: absolute;
    top: 100%;
    //width: calc(100% - (2 * calc(var(--bs-gutter-x) * 0.5)));
    padding: 0;
    list-style: none;
    width: 100%;
    li{
        padding: 0.375rem 0.75rem;
        width: 100%;
        background:#fff;
        transition: all 0.3s ease;
        &:hover{
            background-color: var(--bs-primary);
            color: #fff;
        }
    }
}

#footer p{
    font-size: 0.8rem;
}
#footer img{
    width: 14px;
}
